import { Article } from '@mui/icons-material'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined'
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined'
import SecurityUpdateWarningOutlinedIcon from '@mui/icons-material/SecurityUpdateWarningOutlined'
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined'

import { routes } from '@redwoodjs/router'

import MenuItem from 'src/types/MenuItem'

const styles = {
  iconColor: {
    color: 'rgba(0,0,0,0.5)',
  },
}

const menuItems = (hasRole: (rolesToCheck: string | string[]) => boolean) => {
  const items: MenuItem[] = []

  if (hasRole('admin-dashboard')) {
    items.push({
      primaryText: 'Dashboard',
      linkTo: routes.adminDashboard(),
      icon: <DashboardOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-notification-management')) {
    items.push({
      primaryText: 'Notification Management',
      linkTo: routes.adminNotificationOverview(),
      icon: <NotificationsNoneOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-update-management')) {
    items.push({
      primaryText: 'Update Management',
      linkTo: routes.adminUpdateOverview(),
      icon: <SecurityUpdateWarningOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-feedback-management')) {
    items.push({
      primaryText: 'Feedback Management',
      linkTo: routes.adminFeedbackOverview(),
      icon: <SecurityUpdateWarningOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-record-management') || hasRole('admin-record-information') || hasRole('admin-user-support')) {
    items.push({
      primaryText: 'Record Management',
      linkTo: routes.adminRecordOverview(),
      icon: <PlagiarismOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-dataset-management') || hasRole('admin-dataset-information') || hasRole('admin-user-support')) {
    items.push({
      primaryText: 'Dataset Management',
      linkTo: routes.adminDatasetOverview(),
      icon: <InsertChartOutlinedOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-user-management') || hasRole('admin-user-information') || hasRole('admin-user-support')) {
    items.push({
      primaryText: 'User Management',
      linkTo: routes.adminUserOverview(),
      icon: <PeopleAltOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-usage-statistics')) {
    items.push({
      primaryText: 'Record Statistics',
      linkTo: routes.adminRecordStatistics(),
      icon: <QueryStatsOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-storage-statistics')) {
    items.push({
      primaryText: 'Storage Statistics',
      linkTo: routes.adminStorageStatistics(),
      icon: <StorageOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-usage-statistics')) {
    items.push({
      primaryText: 'User Statistics',
      linkTo: routes.adminUserStatistics(),
      icon: <ContactPageOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-usage-statistics')) {
    items.push({
      primaryText: 'Organisational Unit Statistics',
      linkTo: routes.adminOrganisationalUnitsStatisticsOverview(),
      icon: <ApartmentOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-system-statistics')) {
    items.push({
      primaryText: 'Email Statistics',
      linkTo: routes.adminEmailStatistics(),
      icon: <EmailOutlinedIcon style={styles.iconColor} />,
    })

    items.push({
      primaryText: 'User Sessions Statistics',
      linkTo: routes.adminUserSessionStatistics(),
      icon: <ContactsOutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-group-management')) {
    items.push({
      primaryText: 'Group Management',
      linkTo: routes.adminUserGroupOverview(),
      icon: <Diversity3OutlinedIcon style={styles.iconColor} />,
    })
  }

  if (hasRole('admin-queue-management')) {
    items.push({
      primaryText: 'Queue Management',
      linkTo: routes.adminQueueOverview(),
      icon: <QueuePlayNextOutlinedIcon style={styles.iconColor} />,
    })
  }
  if (hasRole('developer-access')) {
    items.push({
      primaryText: 'Developer Console',
      linkTo: routes.adminDeveloperConsole(),
      icon: <ImportantDevicesOutlinedIcon style={styles.iconColor} />,
    })
  }

  items.push({
    primaryText: 'Logout',
    secondaryText: 'Sign out of UQRDM',
    linkTo: routes.logout(),
    icon: <LogoutOutlinedIcon style={styles.iconColor} />,
  })

  if (hasRole('grad-school-dashboard')) {
    items.push({
      primaryText: 'Graduate School Dashboard',
      secondaryText: 'View and manage thesis submissions',
      linkTo: routes.graduateSchoolDashboard(),
      icon: <Article style={styles.iconColor} />,
    })
  }

  items.push({
    primaryText: 'Back to UQRDM',
    linkTo: routes.dashboard(),
    icon: <ArrowBackOutlinedIcon style={styles.iconColor} />,
  })

  return items
}

export default menuItems
