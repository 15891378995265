import { useEffect } from 'react'

import StayCurrentLandscapeIcon from '@mui/icons-material/StayCurrentLandscape'
import { Alert, Snackbar, Theme, useMediaQuery } from '@mui/material'

const MobileNotice = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)
  useEffect(() => {
    if (isMobile) {
      setOpen(true)
    }
  }, [setOpen, isMobile])
  if (isMobile === false) return null
  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} sx={{ flex: 1 }}>
      <Alert
        icon={
          <StayCurrentLandscapeIcon
            sx={{
              fontSize: 40,
              animation: 'spin 10s linear 1',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(90deg)',
                },
                '20%': {
                  transform: 'rotate(90deg)',
                },
                '50%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(0deg)',
                },
              },
            }}
          />
        }
        onClose={handleClose}
        severity="info"
        variant="filled"
        sx={{
          margin: 2,
          width: '100% !important',
          flex: 1,
        }}
      >
        Please turn your mobile device to landscape for a better experience.
      </Alert>
    </Snackbar>
  )
}

export default MobileNotice
